.passport-info{
    display: flex;
    margin-top: 4rem;
    justify-content: space-between;
    .pass-uploader{
        flex: 0.5;
    }
    .pass-img{
        text-align: end;
        flex:0.5;
        svg{
            max-width: 540px;
        }
    }
}
.heading-for-mobile-view{
    display: none;
}
.pass-verifi-li{
    color:#3A98B9
}
@media screen and (max-width: 767px) {
    .heading-for-mobile-view{
        display:block;
    }
    .heading-for-desktop-view{
        display: none;
    }
    .passport-info{
        flex-direction: column;
        .pass-img{
            text-align: center;}
        
    }

    .pass-uploader:nth-child(1) { order: 2; }
        .pass-img:nth-child(2) { order: 1; }
}