@font-face {
    font-family: 'Titillium Web';
    src: url('./assets/font/TitilliumWeb-Regular.ttf');
  }
  body{
    font-family: 'Titillium Web';
    margin: 0;
  }
  h1,h2,h3,h4,h5,h6{
    margin: 0;
  }
  p{
    margin-top: 0;
  }