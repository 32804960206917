$primary:#3A98B9;
$secondary:#E9B88C;
$text:#686868;
@for $size from 10 through 96 {
    .fs--#{$size} {
      font-size: $size/16 + rem !important;
    }
  }
  $weights: 100 200 300 400 500 600 700 800;
  @each $weight in $weights {
    .fw--#{$weight} {
      font-weight: $weight !important;
    }
  }
  .primary-color{
    color: $primary;
  }
  .secondary-color{
    color:$secondary
  }
  .text{
    color:$text
  }
  .container{
max-width: 1440px;
margin: 0 auto;

  }
  @media screen and (max-width: 1440px) {
    .container{
       padding: 0 15px;
    }
  }
button{
    background: $primary;
    border-radius: 8px; 
    border-color: transparent;
    padding: 8px 24px;
    color: white;
    font-size: 13px;
    font-weight: 400;
    height: 56px;
    cursor: pointer;
}
input{
  border: none;
  border-bottom: 1px solid #989898;
  padding: 10px 0;
  display: block;
  width:90%;
  margin: 10px 0 0 0;

}
input:focus-visible{
  outline:none
}
.secondary-button{
   background: #EEEEEE;
   color: #A5A5A5;
   cursor: pointer;
}

.social-icons{
  display: flex;
  gap:9px;
  // margin-top: 35px;
}
.icons{
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  border-radius: 50%;
}
.icons:hover{
  background: #3A98B9
}
.react-icons{
  color:#A5A5A5
}
.react-icons:hover{
  color:white
}
.allow-file{
  margin-top: 24px;
}
.image-uploader-box{
  
  margin-top: 24px;
  border-radius: 16px;
  height: 250px;
  text-align: center;
  border: 2px dashed #E9B88C;
  background: #F7F7F7;
  .uploaded-image{
    border-radius: 16px;
  }.image-uploader-icon{
    padding: 72px 0;
      }

 }  
 .next-btn-box{
  text-align: end;
}
.next-btn{
margin: 60px 0;
width: 108px;
}
.input-for-upload-docs{
  display: none;
}
ul.breadcrumb {
  padding: 0px 10px;
  list-style: none;
  // background-color: #eee;
}
ul.breadcrumb li {
  display: inline;
  font-size: 13px;
}
ul.breadcrumb li+li:before {
  padding: 8px;
  color: #A5A5A5;
  content: "/\00a0";
}
.error-msg{
  color: red;
}