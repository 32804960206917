.id-image{
   display: flex;
   margin-top: 42px;
   gap: 50px;
   .front-side{
    flex:0.5;
   } 
   .back-side{
    flex:0.5;
   }

}
.id-card-card{
   width: 50% !important;
}
.id-verifi-li{
   color: #3A98B9;
}
@media screen and (max-width: 767px) {

   .id-image{
      flex-direction: column;
      gap: 10px;
   }
}