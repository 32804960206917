.offer{
    background-color: #3A98B9;
    text-align: end;
    color:white;
    padding: 10px 0;
}
.logo{
    background-color: #F7F7F7;
    // padding: 20px 0;
}
.kyc-footer{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border-top: 2px solid #EEEEEE;
    margin-top: 1.4rem;
    padding-top: 1rem;
    color: #686868;
}

.back-btn{
    margin-right: 16px;
    width: 108px;
    background: #EEEEEE;
    color: #A5A5A5;

}
.upload-complete-btn-box{
  text-align: center;
}
.upload-complete-btn{
   
width: 200px;  
margin-bottom: 4rem; }