.comp-KYC{
    display: flex;
   align-items: end;
    margin-top: 4rem;
    gap: 2rem;
    .comp-KYC-form{
flex: 0.5;
.flagAndName{
    display: flex;
    width: 90%;
    align-items: center;
    gap: 6px;
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid #989898;
    img{
        width: 16px;
        height: 11px;
    }
    p{margin: 0;}
}
    }
    .gov-issued-doc{
        flex: 0.5;

    }
}
.docs-verification-link{
    display: flex;
    gap:30px;
    .ID-card{
        max-width: 273px;
        text-align: center;
        width: 100%;
        border-radius: 16px;
        background: #3A98B9;

        color: white;

        padding: 32px 0;
flex:0.5;
    }
    .passport{
        max-width: 273px;
        width: 100%;
        text-align: center;
        border-radius: 16px;
        background: #E9B88C;
        padding: 32px 0;
        color: white;
        flex:0.5;

    }
    .docs-btn{
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1E1E1E;
        border-radius: 8px;
        margin: 20px auto 0 auto;
    }
}
.react-tel-input .selected-flag{
    padding: 0 0 0 0 !important;
}
.error-msg{
    color: red;
}
.selected-doc-border{
    border: 3px solid black;
}
.personal-verifi-li{
    color: #3A98B9;

}
@media screen and (max-width: 767px) {
    .comp-KYC{
display: block;        
    }
    .gov-issued-doc{
        margin-top: 22px;
    }
}