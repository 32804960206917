.img-verfi{
    margin-top: 4rem;
display: flex;
gap: 69px;
.img-verifi-uploader{
    flex: 0.5;
}
.iimage{
    flex:0.5;
    text-align: end;
}
}
.image-verifi-li{
    color:#3A98B9;
}
.img-verifi-mobile-header{
    display: none;
}
@media screen and (max-width: 767px) {
    .img-verfi{
        flex-direction:column;
        .img-verifi-uploader:nth-child(1) { order: 2; }
        .iimage:nth-child(2) { order: 1;
        text-align: center;
    img{
        max-width: 100%;
        width:100%;
    } }
    }
 
    .img-verifi-mobile-header{
        display: block;
    }
    .img-verifi-desktop-header{
        display: none;
    }
 
}